import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import PeopleList from "../components/peopleList";
import PeopleTitle from "../components/peopleTitle";
import peopleData from "../data/pastSpeakers.json";

const People = ({ data }) => {
  const { homepage } = data.siteMetaData;

  const dataA = []
  const dataF = []
  const dataK = []
  const dataP = []
  const dataU = []

  // アルファベット順に並び替え
  peopleData.sort((a, b) => {
    if (`${a.english_last_name}${a.english_first_name}` < `${b.english_last_name}${b.english_first_name}`) return -1
    else if (`${a.english_last_name}${a.english_first_name}` > `${b.english_last_name}${b.english_first_name}`) return 1
    return 0
  })

  // 配列を各段落ごとに分ける
  peopleData.forEach((item) => {
    const target = item.english_last_name.substring(1, 0).toUpperCase()
    if (target.match('A|B|C|D|E')) {
      dataA.push(item)
    }
    if (target.match('F|G|H|I|J')) {
      dataF.push(item)
    }
    if (target.match('K|L|M|N|O')) {
      dataK.push(item)
    }
    if (target.match('P|Q|R|S|T')) {
      dataP.push(item)
    }
    if (target.match('U|V|W|X|Y|Z')) {
      dataU.push(item)
    }
  })

  return (
    <Layout {...homepage}>
      <link href="https://fonts.googleapis.com/css?family=Arvo:400,700&display=swap" rel="stylesheet"/>

      <Seo title="PAST SPEAKERS" />
      <div css={container}>

        <div css={section}>
          <PeopleTitle
            text="PAST SPEAKERS"
          />
          <p css={text}>※会社（団体）名・肩書は登壇時のものです</p>

          <ul css={anchor} id="ps-order-index">
            <li><a href="#ps-order-abcde">ABCDE</a></li>
            <li><a href="#ps-order-fghij">FGHIJ</a></li>
            <li><a href="#ps-order-klmno">KLMNO</a></li>
            <li><a href="#ps-order-pqrst">PQRST</a></li>
            <li><a href="#ps-order-uvwxyz">UVWXYZ</a></li>
          </ul>

          <div css={peopleList} id="ps-order-abcde">
            <p css={peopleIndex}><span/>ABCDE</p>
            <PeopleList
              data={dataA}
            />
          </div>

          <a css={button} href="#ps-order-index">他のSpeakerを見る</a>

          <div css={peopleList} id="ps-order-fghij">
            <p css={peopleIndex}><span/>FGHIJ</p>
            <PeopleList
              data={dataF}
            />
          </div>

          <a css={button} href="#ps-order-index">他のSpeakerを見る</a>

          <div css={peopleList} id="ps-order-klmno">
            <p css={peopleIndex}><span/>KLMNO</p>
            <PeopleList
              data={dataK}
            />
          </div>

          <a css={button} href="#ps-order-index">他のSpeakerを見る</a>

          <div css={peopleList} id="ps-order-pqrst">
            <p css={peopleIndex}><span/>PQRST</p>
            <PeopleList
              data={dataP}
            />
          </div>

          <a css={button} href="#ps-order-index">他のSpeakerを見る</a>

          <div css={peopleList} id="ps-order-uvwxyz">
            <p css={peopleIndex}><span/>UVWXYZ</p>
            <PeopleList
              data={dataU}
            />
          </div>

          <a css={button} href="#ps-order-index">他のSpeakerを見る</a>

        </div>

      </div>
    </Layout>
  );
};
export default People;

const container = css`
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 767px) {
    width: 94%;
    display: block;
    padding: 50px 0 0;
  }
`
const section = css`
  margin-bottom: 120px;
  @media (max-width: 767px) {
    margin-bottom: 60px;
  }
`
const text = css`
  margin-bottom: 70px;
  font-size: 0.875rem;
  text-align: center;
  a {
    font-size: 0.875rem;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
`
const anchor = css`
  width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  li {
    padding-left: 8px;
    width: calc((100% - 5px - 5px) / 5);
    height: 60px;
    white-space: nowrap;
    a {
      font-size: 1.75rem;
      display: block;
      color: rgb(1, 72, 198);
      width: 100%;
      line-height: 60px;
      letter-spacing: 5px;
      text-align: center;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(1, 72, 198);
      border-image: initial;
      border-radius: 4px;
      &:before {
        content: "";
        border-left: 2px solid #0148c6;
        border-bottom: 2px solid #0148c6;
        width: 12px;
        height: 12px;
        display: inline-block;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        line-height: 39px;
        position: relative;
        top: -8px;
        margin-right: 10px;
      }
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    flex-wrap: wrap;
    li {
      width: calc(50% - 3.5px);
      height: 50px;
      a {
        line-height: 41px;
        font-size: 1.25rem;
        &:before {
          width: 9px;
          height: 9px;
          top: -4px;
        }
      }
    }
  }
`
const peopleList = css`
  width: 1160px;
  margin: 30px auto;
  @media (max-width: 767px) {
    width: 100%;
  }
`
const peopleIndex = css`
  margin-bottom: 30px;
  font-size: 2.375rem;
  letter-spacing: 11.4px;
  font-weight: normal;
  span {
    margin-right: 20px;
    position: relative;
    width: 31.5px;
    height: 33px;
    display: inline-block;
    overflow: hidden;
    &:before {
      width: 15.31px;
      height: 15.31px;
      content: "";
      position: absolute;
      border: 1px solid #000;
      border-radius: 50%;
      left: 8px;
    }
    &:after {
      content: "";
      position: absolute;
      border: 1px solid #000;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      top: 17px;
    }
  }
  @media (max-width: 767px) {
    font-size: 1.25rem;
    letter-spacing: 6px;
    span {
      margin-right: 10px;
      width: 24.77px;
      height: 26.02px;
      &:before {
        width: 12.65px;
        height: 12.65px;
        left: 5px;
      }
      &:after {
        width: 23px;
        height: 23px;
        top: 14px;
      }
    }
  }
`
const button = css`
  width: 304px;
  margin: 0 auto 60px auto;
  line-height: 26px;
  padding: 16.55px 0 17.45px 0;
  text-align: center;
  border: 1px solid #000;
  border-radius: 40px;
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  @media (max-width: 767px) {
    width: 224px;
    line-height: 23px;
    padding: 10.55px 0 11.45px 0;
    font-size: 1.125rem;
  }
`

export const query = graphql`
  {
    siteMetaData: site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;
